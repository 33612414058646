import React,{useState,useEffect,useRef} from 'react';
import { ZIM } from "zego-zim-web";
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { Link,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Peer from "simple-peer";
import { io } from "socket.io-client";
import { useDispatch,useSelector } from "react-redux";
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import {
  getLoginStatus, getUser, selectIsLoggedIn, selectUser, RESET, getCalendarData, getByDefaultUsers,
} from "../redux/features/auth/authSlice";
import Sidebar from "../components/Sidebar";
import {
  getConversations,
  updateMessagesAndConversations,
} from "../redux/features/chat/chatSlice";
import KbmChatHome from "../components/chat/KbmChatHome";
import ChatContainer from "../components/chat/ChatContainer";
import SocketContext from "../context/SocketContext";
import Call from "../components/chat/call/Call";
import {
  getConversationId,
  getConversationName,
  getConversationPicture,
} from "../utils/chat";
import axios from 'axios';

// const callData = {
//   socketId: "",
//   receiveingCall: false,
//   callEnded: false,
//   name: "",
//   picture: "",
//   signal: "",
// };

// const socket = io.connect(process.env.REACT_APP_BACKEND_URL);
const callUrl = process.env.REACT_APP_BACKEND_URL;
const appID = Number(process.env.REACT_APP_ID_ZEGO);
const serverSecret = process.env.REACT_APP_SECRET_KEY_ZEGO;

function randomID(len) {
  let result = '';
  if (result) return result;
  var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

const Home = ({ socket }) => {

  useRedirectLoggedOutUser('/login')	

	// console.log(socket);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isLoggedIn = useSelector(selectIsLoggedIn);
	const { user, users, shiftBookings } = useSelector((state) => state.auth);
	// const user = useSelector(selectUser);
	const { activeConversation } = useSelector((state) => state.chat);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [typing, setTyping] = useState(false);
  const [message, setMessage] = useState();
  const [notifications, setNotifications] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [defaultUsers, setDefaultUsers] = useState([]);
  // const [calendarData, setCalendarData] = useState([shiftBookings]);
  // const [unreadCount, setUnreadCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(() => {
    // Initialize from localStorage or an empty object if not present
    const storedUnreadCounts = localStorage.getItem("unreadCount");
    return storedUnreadCounts ? JSON.parse(storedUnreadCounts) : {};
  });

  useEffect(() => {
    // Update localStorage whenever unreadCounts changes
    localStorage.setItem("unreadCount", JSON.stringify(unreadCount));
  }, [unreadCount]);

  // const audioRef = useRef(null);

  // const [call, setCall] = useState(callData);
  // const [stream, setStream] = useState(null);
  // const [show, setShow] = useState(false);
  // const { receiveingCall, callEnded, socketId } = call;
  // const [callAccepted, setCallAccepted] = useState(false);
  // const [totalSecInCall, setTotalSecInCall] = useState(0);
  // const [isCameraOn, setIsCameraOn] = useState(true);
  // const myVideo = useRef(null);
  // const userVideo = useRef();
  // const connectionRef = useRef();

// console.log(user?.token);

  // const toggleCamera = () => {
  //   if (isCameraOn) {
  //     // If camera is currently on, stop the stream
  //     stream.getTracks().forEach((track) => track.stop());
  //   } else {
  //     navigator.mediaDevices.getUserMedia({ video: true, audio: true })
  //       .then((newStream) => {
  //         setStream(newStream);
  //         myVideo.current.srcObject = newStream;
  //       })
  //       .catch((error) => console.error('Error accessing camera:', error));
  //   }

  //   setIsCameraOn((prev) => !prev);
  // };
  
	// console.log('activeConversation',activeConversation)
  useEffect(() => {
    if (user?.roles?.includes('student')) {
      dispatch(getCalendarData());
    }
  }, [user, dispatch]);

 // useEffect(() => {
 //    if (user) {
 //      dispatch(getConversations(user));
 //    }
 //  }, [dispatch, user]);

 useEffect(() => {
    socket.emit("join", user?._id);
    //get online users
    socket.on("get-online-users", (users) => {
      setOnlineUsers(users);
    });
  }, [user]);

 const messageTone = new Audio('../audio/notification.mp3');

  useEffect(() => {
    //lsitening to receiving a message
    socket.on("receive message", (message) => {
      // console.log(message);
      // setMessage(message);
      dispatch(updateMessagesAndConversations(message));
      messageTone.play();
    });

    // return () => {
    //   socket.off("receive message");
    // };

    //listening when a user is typing
    socket.on("typing", (conversation) => setTyping(conversation));
    socket.on("stop typing", () => setTyping(false));
  }, []);

//   const fetchCalendarData = async () => {
//     try {
//         const { data } = await axios.get(
//             `${process.env.REACT_APP_BACKEND_URL}/api/users/getCalendarData`,
//             {
//                 headers: {
//                     Authorization: `Bearer ${user.token}`,
//                 },
//             }
//         );
//         console.log('Fetched data:', data); // Log the fetched data
//         setCalendarData(data);
//     } catch (error) {
//         console.error('Error fetching calendar data:', error);
//     }
// };

// useEffect(() => {
//         fetchCalendarData();
// }, []);

// useEffect(() => {
// }, [calendarData]);
    // console.log('Calendar data updated:', shiftBookings); // Log calendarData to ensure it's being fetched correctly

  // useEffect(() => {
  //   // Event listener for receiving notifications
  //   socket.on("receive notification", (notification) => {
  //     // Update unread message count when a new notification is received

  //     setUnreadCount(notification.unreadCount);
  //   });

  //   // Clean up event listeners when component unmounts
  //   return () => {
  //     socket.off("receive notification");
  //   };
  // }, []);

  // const handleReadMessages = () => {
  //   // Emit "read messages" event to the server
  //   socket.emit("read messages", user?._id); // You need to replace userId with the actual user ID
  //   // Reset unread message count locally
  //   setUnreadCount(0);
  // };

  // useEffect(() => {
    // setupMedia();
  //   socket.on("setup socket", (id) => {
  //     setCall({ ...call, socketId: id });
  //   });
  //   socket.on("call user", (data) => {
  //     setCall({
  //       ...call,
  //       socketId: data.from,
  //       name: data.name,
  //       picture: data.picture,
  //       signal: data.signal,
  //       receiveingCall: true,
  //     });
  //   });
  //   socket.on("end call", () => {
  //     setShow(false);
  //     setCall({ ...call, callEnded: true, receiveingCall: false });
  //     myVideo.current.srcObject = null;
  //     if (callAccepted) {
  //       connectionRef?.current?.destroy();
  //     }
  //   });
  // },[socket])
  // console.log('socketId---->',socketId);

  // const callUser = () =>{
  //   enableMedia();
  //   setCall({
  //     ...call,
  //     name: getConversationName(user, activeConversation.users),
  //     picture: getConversationPicture(user, activeConversation.users),
  //   });
  //   const peer = new Peer({
  //     initiator: true,
  //     trickle: false,
  //     stream: stream,
  //   });
  //   peer.on("signal", (data) => {
  //     socket.emit("call user", {
  //       userToCall: getConversationId(user, activeConversation.users),
  //       signal: data,
  //       from: socketId,
  //       name: user.name,
  //       picture: user.picture,
  //     });
  //   });
  //   peer.on("stream", (stream) => {
  //     userVideo.current.srcObject = stream;
  //   });
  //   socket.on("call accepted", (signal) => {
  //     setCallAccepted(true);
  //     peer.signal(signal);
  //   });
  //   connectionRef.current = peer;
  // }

  //--answer call  funcion
  // const answerCall = () => {
  //   enableMedia();
  //   setCallAccepted(true);
  //   const peer = new Peer({
  //     initiator: false,
  //     trickle: false,
  //     stream: stream,
  //   });
  //   peer.on("signal", (data) => {
  //     socket.emit("answer call", { signal: data, to: call.socketId });
  //   });
  //   peer.on("stream", (stream) => {
  //     userVideo.current.srcObject = stream;
  //   });
  //   peer.signal(call.signal);
  //   connectionRef.current = peer;
  // };

  // const setupMedia = () => {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then((stream) => {
  //       setStream(stream);
        
  //     });
  // };

  // const endCall = () => {
  //   setShow(false);
  //   setCall({ ...call, callEnded: true, receiveingCall: false });
  //   myVideo.current.srcObject = null;
  //   socket.emit("end call", call.socketId);
  //   connectionRef?.current?.destroy();
  // };

  // const enableMedia = () =>{
  //   myVideo.current.srcObject = stream;
  //   setShow(true);
  // }

  function randomID(len) {
  let result = '';
  if (result) return result;
  var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

  // 567629559 for chat key app id
  // 49a76e2ac8a744b6ffe9715d3aa6f05b
  // const appID = 1812578938;
  // const userID = randomID(5);
  // const userName = 'user_' + userID;
        // const serverSecret = "f2b659b26fa78bc21967611dbc6f79e9";
        // generate Kit Token
        const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, randomID(5),  user?._id,  user?.name);

        // Create instance object from Kit Token.
        const zp = ZegoUIKitPrebuilt.create(kitToken);
        zp?.addPlugins({ ZIM });

        const handleSend = (callType) => {
          const targetUser = {
            userID: getConversationId(user, activeConversation.users),
            userName: getConversationName(user, activeConversation.users)
          };

          zp.sendCallInvitation({
            callees: [targetUser],
            callType: callType,
            timeout: 60, // Timeout duration (second). 60s by default, range from [1-600s].
            scenario: {
              mode: ZegoUIKitPrebuilt.GroupCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
              config: {
                turnOnMicrophoneWhenJoining: true,
                turnOnCameraWhenJoining: callType === ZegoUIKitPrebuilt.InvitationTypeVideoCall,
              },
            },
            ringtoneConfig: {
              outgoingCallUrl: '../audio/ringing.mp3', // The ringtone when receiving an incoming call invitation.
              incomingCallUrl: '../audio/ringtone.mp3' // The ringtone when sending a call invitation.
            },
          })
          .then((res) => {
            console.warn(res);
            if (res.errorInvitees.length) {
              alert('The user does not exist or is offline.');
            }
          })
          .catch((err) => {
            console.error(err);
          });

          zp.setCallInvitationConfig({
            ringtoneConfig: {
              outgoingCallUrl: '../audio/ringing.mp3', // The ringtone when receiving an incoming call invitation.
              incomingCallUrl: '../audio/ringtone.mp3' // The ringtone when sending a call invitation.
            },
          });
        };

      const videoCall = async () =>{
        handleSend(ZegoUIKitPrebuilt.InvitationTypeVideoCall);
        // try {
        //   const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        //   if (audioRef.current) {
        //     audioRef.current.srcObject = mediaStream;
        //   }
        //   handleSend(ZegoUIKitPrebuilt.InvitationTypeVideoCall);
        // } catch (error) {
        //   console.error('Error accessing microphone:', error);
        // }
      }
      const audioCall = async () =>{
        handleSend(ZegoUIKitPrebuilt.InvitationTypeVoiceCall);
        // try {
        //   const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        //   if (audioRef.current) {
        //     audioRef.current.srcObject = mediaStream;
        //   }
        //   handleSend(ZegoUIKitPrebuilt.InvitationTypeVoiceCall);
        // } catch (error) {
        //   console.error('Error accessing microphone:', error);
        // }
      }

      const [isHidden, setIsHidden] = useState(false);
      
      // const data = {
        //   "_id": "5f9ae62527a61f5d05227bd2",
        //   "company": "5f0464bbfbdf285500aa3d1e",
        //   "type": "general_booking",
        //   "date": "2024-06-26T00:00:00.000+00:00",
        //   "location": "Online Group 1 - KBM_training1",
        //   "status": "Active",
        //   "updated_at": "2020-10-29T15:57:49.000+00:00",
        //   "created_at": "2020-10-29T15:56:21.000+00:00",
        //   "bookings": [
        //     {
        //       "user": "5f9aa94f27a61f55bd7fdf72",
        //       "seat": 1,
        //       "shift": "5f9ab4ee27a61f56f62e6b62",
        //       "status": "Active",
        //       "updated_at": "2020-10-29T15:57:36.000+00:00",
        //       "created_at": "2020-10-29T15:56:21.000+00:00",
        //       "_id": "5f9ae62527a61f5d05227bd3",
        //       "cancelledBy": "5f9aa94f27a61f55bd7fdf72",
        //       "deleted_at": "2020-10-29T15:57:36.000+00:00"
        //     },
        //     {
        //       "user": "5f9aa94f27a61f55bd7fdf72",
        //       "seat": 1,
        //       "shift": "5f9ab4ee27a61f56f62e6b62",
        //       "status": "Active",
        //       "updated_at": "2020-10-29T15:57:49.000+00:00",
        //       "created_at": "2020-10-29T15:57:49.000+00:00",
        //       "_id": "5f9ae67d27a61f5e0439af14"
        //     }
        //   ]
        // };

      // useEffect(() => {
      //   // const filteredUsers = users.filter(user => 
      //   //     user.email === 'online.training1@kbmgroup.co.uk' || 
      //   //     user.email === 'online.training2@kbmgroup.co.uk'
      //   // );

      //   // const names = []

      //   const assignRandomNames = (users) => {
      //     // if (!Array.isArray(users)) {
      //     //     throw new TypeError("Expected an array of users");
      //     // }

          
      //     //   for (let i = 1; i <= users.length; i++) {
      //     //   names.push(`Online Training ${i}`); // Push names into the array
      //     // } 
      //     // const updatedUsers = users.map((user, index) => ({
      //     //     ...user,
      //     //     name: names[index % names.length]
      //     // }));
      //     // // console.log(names)
      //     // return updatedUsers;
      //       // const shuffledNames = names.sort(() => 0.5 - Math.random());
      //     const names = ["Barking", "Park Royal", "Training 3"];
      //     return users.map((user, index) => ({
      //           ...user,
      //           name: names[index % names.length]
      //       }));
      //   };

      //   const usersWithRandomNames = assignRandomNames(users);
      //   setDefaultUsers(usersWithRandomNames);

      //   const shouldShowDefaultUser = (userId) => {
      //     const isUserInBooking = shiftBookings.some(booking => booking.bookings.some(b => b.user === userId));
      //     // console.log(isUserInBooking);
      //     if (!isUserInBooking) {
      //       return false;
      //     }

      //     const currentDate = new Date();
      //     let targetDate;
      //     let location;

      //     for (const booking of shiftBookings) {
      //         if (booking.bookings.some(b => b.user === userId)) {
      //             targetDate = new Date(booking.date);
      //             location = booking.location;
      //             break;
      //         }
      //     }

      //     if (!targetDate || !location) {
      //         return false;
      //     }

      //     // const location = shiftBookings.location;
      //     if (location === 'Online Group 1 - KBM_training1' && currentDate > targetDate) {
      //       // setIsEnabled(true);
      //       // setDefaultUsers(usersWithRandomNames.filter(user => user.name === 'Barking'));
      //       const usersWithRandomNames = assignRandomNames(users);
      //       setDefaultUsers(usersWithRandomNames);
      //       const isBarkingPresent = usersWithRandomNames.some(user => user.name === 'Barking');
      //       setIsEnabled(isBarkingPresent);
      //       return true;
      //     }else {
      //       setIsEnabled(false);
      //       const usersWithRandomNames = assignRandomNames(users);
      //       setDefaultUsers(usersWithRandomNames);
      //     }

      //     // if (location === 'Online Group 2 - KBM_training2' && currentDate > targetDate) {
      //     //   setIsEnabled(true);
      //     //   setDefaultUsers(usersWithRandomNames.filter(user => user.name === 'Park Royal'));
      //     //   // const usersWithRandomNames = assignRandomNames(users);
      //     //   // setDefaultUsers(usersWithRandomNames);
      //     //   return true;
      //     // }else {
      //     //   setIsEnabled(false);
      //     //   const usersWithRandomNames = assignRandomNames(users);
      //     //   setDefaultUsers(usersWithRandomNames);
      //     // }

      //     return false;
      //   };

      // if (user) {
      //   shouldShowDefaultUser(user._id);
      // }

      // }, [user, users, shiftBookings]);

      useEffect(() => {
        const shouldShowDefaultUser = (userId) => {
          /* START this code is works when BST Time is Back in April*/

          const currentDate = new Date();
          //const todayISO = currentDate.toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

          // Calculate yesterday's date
          const yesterdayDate = new Date(currentDate);
          yesterdayDate.setDate(currentDate.getDate() - 1);
          const yesterdayISO = yesterdayDate.toISOString().split('T')[0];

          //console.log('Yesterday:', yesterdayISO);

          let isUserEnabled = false;
          const defaultUsersSet = new Set();

          // Iterate through shiftBookings once
          for (const bookingData of shiftBookings) {
           // console.log(bookingData,'111')
            const bookingDateISO = new Date(bookingData.date).toISOString().split('T')[0];

            // Filter out bookings not matching yesterday's date
            if (bookingDateISO !== yesterdayISO) {
              continue;
            }

            // Check if the user is in the booking and not deleted
            const isUserInBooking = bookingData.bookings.some(b =>
                b.user === userId &&
                (b.deleted_at === undefined || b.deleted_at === null)
            );
            //console.log(isUserInBooking)

            //console.log('User exists in booking:', isUserInBooking);

            if (isUserInBooking) {
              const targetDate = new Date(bookingData.date);
              const location = bookingData.location;

              // console.log('Target Date:', targetDate, '-', targetDate.toISOString());
              // console.log('Yesterday Date:', yesterdayDate, '-', yesterdayDate.toISOString());
              // console.log('location:', location);

              if (!targetDate || !location) {
                continue;
              }

              // Compare the targetDate with yesterdayDate using getTime for consistent comparison
              if (yesterdayDate.getTime() < targetDate.getTime()) {
                isUserEnabled = true;

          /* END this code is works when BST Time is Back in April*/
          /*const currentDate = new Date();
          const todayISO = currentDate.toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

// Console output for debugging
          //console.log('Today:', todayISO);

          let isUserEnabled = false;
          const defaultUsersSet = new Set();

// Iterate through shiftBookings once
          for (const bookingData of shiftBookings) {
            const bookingDateISO = new Date(bookingData.date).toISOString().split('T')[0];

            // Filter out bookings not matching today's date
            if (bookingDateISO !== todayISO) {
              continue;
            }

            // Check if the user is in the booking and not deleted
            const isUserInBooking = bookingData.bookings.some(b =>
                b.user === userId &&
                (b.deleted_at === undefined || b.deleted_at === null)
            );

            // Console output for debugging
            //console.log('User exists in booking:', isUserInBooking);

            if (isUserInBooking) {
              const targetDate = new Date(bookingData.date);
              const location = bookingData.location;

              // Console output for debugging
              //console.log('Target Date:', targetDate, '-', targetDate.toISOString());
              //console.log('Today Date:', currentDate, '-', currentDate.toISOString());
              //console.log('Location:', location);

              if (!targetDate || !location) {
                continue;
              }

              // Compare the targetDate with currentDate using getTime for consistent comparison
              if (currentDate.getTime() > targetDate.getTime()) {
                isUserEnabled = true;*/

                switch (location) {
                  case 'Online Group 1 - KBM_training1':
                    defaultUsersSet.add('Online Group 1');
                    break;
                  case 'Online Group 2 - KBM_training2':
                    defaultUsersSet.add('Online Group 2');
                    break;
                  case 'Park Royal':
                    defaultUsersSet.add('Park Royal');
                    break;
                  case 'Barking':
                    defaultUsersSet.add('Barking');
                    break;
                  case 'Birmingham':
                    defaultUsersSet.add('Birmingham');
                    break;
                  case 'Croydon':
                    defaultUsersSet.add('Croydon');
                    break;
                  case 'Manchester':
                    defaultUsersSet.add('Manchester');
                    break;
                  case 'Online Bookkeeping & Payroll G1':
                    defaultUsersSet.add('Online Bookkeeping & Payroll G1');
                    break;
                  case 'Online Bookkeeping & Payroll G2':
                    defaultUsersSet.add('Online Bookkeeping & Payroll G2');
                    break;
                  case 'Online Accounts Assistant G1':
                    defaultUsersSet.add('Online Accounts Assistant G1');
                    break;
                  case 'Online Accounts Assistant G2':
                    defaultUsersSet.add('Online Accounts Assistant G2');
                    break;
                  case 'KBM_FA_Practice':
                    defaultUsersSet.add('FA_Practice');
                    break;
                  case 'Online Bookkeeping & Payroll':
                    defaultUsersSet.add('Online Bookkeeping & Payroll');
                    break;
                  case 'Online Accounts Assistant':
                    defaultUsersSet.add('Online Accounts Assistant');
                    break;
                  default:
                    setIsEnabled(false);
                }
              }
            }
          }

          setIsEnabled(isUserEnabled);
          setDefaultUsers(users.filter(user => defaultUsersSet.has(user.name)));

          return isUserEnabled;
        };

        const isStudent = user?.roles?.includes('student');
        if (isStudent) {
          shouldShowDefaultUser(user._id);
        }

        /*if (user) {
      shouldShowDefaultUser(user._id);
    }*/

  }, [user, users, shiftBookings]);

	return (
		<>
			<div className="main">
				<div className="mainContainer">
					<Sidebar 
            onlineUsers={onlineUsers} 
            typing={typing} 
            isHidden={isHidden} 
            setIsHidden={setIsHidden} 
            unreadCount={unreadCount} 
            setUnreadCount={setUnreadCount}
            defaultUsers={defaultUsers}
            />
					{activeConversation?._id ? (
            <ChatContainer 
            onlineUsers={onlineUsers} 
            typing={typing}
            videoCall={videoCall} 
            audioCall={audioCall} 
            isHidden={isHidden} 
            setIsHidden={setIsHidden}
            isEnabled={isEnabled}
            // callUser={callUser}
             />
          ) : (
            <KbmChatHome />
          )}
				</div>
        {/*<div className={`callSec ${(show || call.signal) && !call.callEnded ? "" : "d-none"}`}>
          <Call 
          call={call}
          setCall={setCall}
          callAccepted={callAccepted}
          myVideo={myVideo}
          userVideo={userVideo}
          stream={stream}
          answerCall={answerCall}
          show={show}
          endCall={endCall}
          totalSecInCall={totalSecInCall}
          setTotalSecInCall={setTotalSecInCall}
          toggleCamera={toggleCamera}
          isCameraOn={isCameraOn}
          />
        </div>*/}
        {/*<audio ref={audioRef} autoPlay />*/}
			</div>
		</>
	)
}

const HomeWithSocket = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Home {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default HomeWithSocket